.Root {
  background: #fdf7f7;
  /* background: #dadada; */
  font-family: "Quincy";
}

.App {
  text-align: center;
  min-height: 72vh;
  margin-bottom: 15vh;

}

.logoImage {
  height: 150px;
}

.HeaderContainer {
  display: flex;
  height: 150px
}

.ProfileImageArea {
  margin-right: 3vw;
}

.logo {
  height: 40vmin;
  pointer-events: none;
}

.profileImage {
  width: 25vw;
}

.Navigation {
  background: #272829;
  height: 100px;
}

.contactUs {
  width: 50%;
  text-align: end;
  margin: 25px 6% 0px 0px;
}

.logo {
  width: 50%;
  margin: 0px 0px 0px 3%;
  text-align: left;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.SelectUsHeader {
  font-size: 36px;
  font-weight: 700;
  margin: 35px 0px 35px 0px;
}

.ProjectsHeader {
  font-size: 36px;
  font-weight: 700;
  margin: 35px 0px 35px 0px;
  text-align: left;
}

.CompanyName {
  font-size: 36px;
  font-weight: 700;
  color: #758F71;
}

.SelectUs {
  display: grid;
  align-items: center;
  width: 80%;
  margin-left: 10%;
}

.SelectUsRow {
  display: flex;
  width: 100%;
}

.SelectUsCellHeader {
  font-size: 24px;
  font-weight: 700;
  margin: 10px;
  margin-bottom: 20px;
}

.SelectUsCellContext {
  text-align: justify;
  font-size: 20px;
}

.SelectUsCellContextLastAcapit {
  text-align: justify;
  font-size: 20px;
  font-weight: 700;
  margin-top: 40px;
}

.demoImage {
  width: 100%;
}

.SelectUsImage {
  width: 50%;
  padding: 10px;
  height: fit-content;
}

.SelectUsText {
  width: 50%;
  height: max-content;
  padding: 10px;
}

.SelectUsDetailsText {
  height: max-content;
  padding: 10px;
}

.ProjectsCell {
  width: 33%;
  height: 300px;
  padding: 10px;
}

.NodeNavi {
  background: #758F71;
  color: white;
  height: 100%;
  cursor: pointer;
  width: 200px;
  margin-right: 10px;
  -webkit-clip-path: polygon(25% 0%,
      100% 0%,
      75% 100%,
      0% 100%);
  clip-path: polygon(25% 0%,
      100% 0%,
      75% 100%,
      0% 100%);
  margin-left: -20px;
  display: grid;
  align-content: center;
  text-align: center;
}

.HomeNavi {
  background: #758F71;
  color: white;
  height: 100%;
  cursor: pointer;
  width: 165px;
  margin-right: 10px;
  -webkit-clip-path: polygon(0% 0%,
      100% 0%,
      75% 100%,
      0% 100%);
  clip-path: polygon(0% 0%,
      100% 0%,
      75% 100%,
      0% 100%);
  display: grid;
  align-content: center;
  padding-left: 35px;
}

.LastNodeNavi {
  background: #758F71;
  color: white;
  height: 100%;
  cursor: pointer;
  width: 165px;
  -webkit-clip-path: polygon(0% 0%,
      100% 0%,
      75% 100%,
      0% 100%);
  clip-path: polygon(0% 100%,
      25% 0%,
      100% 0%,
      100% 100%);
  display: grid;
  align-content: center;
  padding-left: 35px;
  margin-left: auto;
  text-align: center;
}

.FloveeNavi {
  display: flex;
  height: 100%;
}

.Footer {
  height: 300px;
  background: #272829;
  margin-top: 50px;
  display: flex;
  justify-content: center;
}

.FooterArea {
  width: 95%;
  display: flex;
  margin-top: 2%;
}

.FooterMainColl {
  /* background:#758F71; */
  color: #758F71;
  width: 45%;

}

.FooterNode {
  text-decoration: none;
  color: #758F71;
  margin-top: 10px;
  width: 40%;
}


.SocialMediaNode {
  display: flex;
  justify-content: right;
  text-decoration: none;
  color: #758F71;
  margin-top: 10px;
}

.SocialMedia {
  height: 50px;
  margin-left: 30px;
}

.FooterColl {
  width: 40%;
  margin-top: 40px;
  font-size: 20px;
  font-weight: 600;
  /* background: #758F71; */
  color: #758F71;
}

.SmallGarden {
  /* width: 80%; */
  display: flex;
}

.SmallGardenTitle {
  margin-top: 100px;
  margin-bottom: 30px;
}

.BigGardenTitle {
  margin-top: 100px;
  margin-bottom: 30px;
  font-size: 25px;
}

button.MuiButtonBase-root {
  width: max-content;
}

.OfferArea {
  font-size: 30px;
  font-weight: 700;
  margin-left: 10%;
  text-align: center;
  width: 80%;
  margin-top: 100px;
  margin-bottom: 100px;
  min-height: 69vh;
}

.contact_area {
  display: flex;
}

.option {
  width: 32%;
  margin: auto;
  /* background: white; */
  background: #dadada;
  border-radius: 15px;
}

.optionTitle {
  color: #758F71;
  font-size: 24px;
  font-weight: 700;
  margin-top: 30px;
  margin-bottom: 30px;
}

.optionPrice {
  color: #758F71;
  font-size: 18px;
  font-weight: 700;
  text-align: right;
  margin-right: 30px;
  margin-bottom: 30px;
}

.optionsPointers {
  text-align: left;
  font-size: 18px;
  height: 325px;
}

.contact_form {
  display: block;
  width: 100%;
  margin-left: 5vw;
}

.contact_form_label {
  display: none;
}

.contact_form_input {
  height: 35px;
  background: #81977f;
  border: none;
  color: black;
  font-size: 18px;
  margin: 10px;
  font-size: 14px;
  padding: 6px;
  width: 100%;
}


::placeholder {
  font-family: Arial, Helvetica, sans-serif;
  color: #272829;
  font-size: 14px;
  padding: 6px;
  opacity: 1;
  /* Firefox */
}

.contact_form_textarea {
  font-family: Arial, Helvetica, sans-serif;
  height: 140px;
  background: #81977f;
  border: none;
  color: #272829;
  font-size: 18px;
  margin: 10px;
  width: 101%;
}

.ContactWithUs {
  margin-top: 20px;
  margin-bottom: 40px;
  /* margin-left: 40px; */
  font-size: 18px;
  font-weight: 700;
}

.MuiButton-text {
  height: 30px;
  width: 20%;
  background-color: white !important;
  border-width: 3px !important;
  border-color: #272829 !important;
  color: #272829 !important;
  margin: 10px !important;
  border-radius: 30px !important;

}

.submit_area {
  width: 100%;
  justify-content: right;
  display: flex;
}

.ContactPage {
  width: 80%;
  margin-left: 10%;
  margin-top: 60px;
  margin-bottom: 60px;
  min-height: 69vh;
}

.ContactDecription {
  font-size: 20px;
  margin-bottom: 40px;
}

.ContactLocation {
  text-align: center;
  width: 100%;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 40px;
}

.LogoFooter {
  height: 250px;
}

.ConfirmationDialog {
  position: absolute;
  top: 50%;
  left: 45%;
  background: white;
  height: 8vh;
  width: 15vw;
  display: none;
}

.MoreInformations {
  display: flex;
}

.whyItWorth {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 20px;
}

.contact_form_content_PC {
  width: 120%;
}

.MoreInformations_PC {
  display: flex;
}

.profileImageContact_PC {
  max-height: 300px;
  width: auto;
  margin-left: 30px;
}

.contact_form_content_Phone {
  width: 60%;
}

.MoreInformations_Phone {
  display: block;
}

.profileImageContact_Phone {
  max-height: 300px;
  width: auto;
}

.FooterColl>.FooterNode {
  display: none;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}